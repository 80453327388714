import theme from './theme';

const baseConfig = {

  baseURL: '/',
  aviakassaUrl: 'https://www.aviakassa.com',
  aviakassaURL: 'https://www.aviakassa.com',
  toolId: '',
  partner: 'AKPS',

  // Application prefix,
  // used for class names, locale storage key names, etc.
  // constant and must be same as in webpack.config.js
  prefix: '',

  routePrefix: 'akf-',

  // PostMessage prefix,
  // used for communications between apps
  postMessagePrefix: 'ak-',

  // API
  apiV4URL: 'https://api4.aviakassa.com/v4/',

  // Offer conditions URL
  offerURL: 'https://www.aviakassa.com/rules',

  // static content url
  staticUrl: 'https://s.aviakassa.ru/',
  staticURL: 'https://s.aviakassa.ru',

  enableApiSwitcher: false,

  // Save get params below in cookies
  bypassGetParams: ['utm', 'marker', 'client_key', 'hit_token', 'apiv4_url', 'actionpay', 'admitad_uid', 'promocode'],

  // Ignore get params below (do not save in cookies)
  ignoreGetParams: ['utm_source', 'utm_medium', 'promocode'],

  // Client key for API
  client_key: '1',

  // Source Id
  utm_source: 'form',

  // Supported languages
  locales: ['ru_RU', 'en_US'],

  // Default locale
  defaultLocale: 'ru_RU',

  // Supported currencies
  currencies: ['RUR'],

  // Default currency
  defaultCurrency: 'RUR',

  defaultLang: 'ru',

  customRailSchemePath: 'assets/actr-rail-scheme/',

  maxRequestTicketedCount: 120,

  // Server time format
  serverTimeFormat: 'HH:mm',
  // Server date format
  serverDateFormat: 'DD.MM.YYYY',
  // Server time format
  serverDateTimeFormat: 'DD.MM.YYYY HH:mm:ss',

  // Redirect to result page after search
  redirectToResultPage: 0,

  // Should open result page with result in new tab
  shouldOpenResultPageInNewTab: 1,

  // Show avia search
  showAvia: 1,

  // Show rail search
  showRail: 1,

  // Show hotel search
  showHotel: 1,

  // Show title
  showTitle: 0, // TODO deprecated, remove
  showAviaTitle: 1,
  showRailTitle: 0,
  showHotelTitle: 0,
  // Custom title in navigation bar
  aviaTitle: '',
  railTitle: '',
  hotelTitle: '',

  // *** AVIA SECTION ***
  // Use sorttypes in results
  aviaSortTypes: ['CHEAP', 'FAST', 'COMFORT'],
  // Group results
  aviaGroupResults: true,
  // Enable insurance
  aviaEnableInsurance: true,
  // Results limit
  aviaResultListLimit: 10,
  // Innner countries with no zagran passport
  aviaInnerCountries: ['ab', 'by', 'kz', 'kg', 'ru'],
  // Metasearch utm_ref
  aviaMetaRefs: ['aviasales', 'yandex', 'momondo', 'skyscanner'],
  // *** AVIA SECTION ***

  // Show aviakassa logo
  showAviakassaLogo: 1,
  // Show locale select
  showLocaleSelect: 1,

  aviaResultShowFilter: true,
  // Avia form options
  aviaSearchLocalstorage: false,
  aviaShowComplexRoute: 1,
  aviaDefaultFrom: null,
  aviaDefaultTo: null,

  // Avia result filter option 'transfers' is set to 'without transfers' by default
  withoutTransfersByDefault: false,

  // Rail form options
  railDefaultFrom: null,
  railDefaultTo: null,

  // Rail autoCancelBook option
  enableAutoRailCancel: true,

  // Enabled payment with alfa miles
  milesEnabled: false,

  // Hotel form options
  hotelDefaultCity: null,

  // Отображать чекбокс подтверждения с правилами на странице заказа
  showConfirmTermsOnCheckoutPage: true,

  searchButtonUseDefaultOptions: true,
  searchButtonTitle: '',
  searchButtonBgColor: '',
  searchButtonTextColor: '',
  searchButtonWidth: 0,
  searchButtonHeight: 0,
  searchButtonXsAutoWidth: true,
  sameLineSearchButton: false,
  showFormFieldPadding: false,
  showNavigationTabs: true,

  enableTracking: false,
  modalOffset: 0,
  railCountryExtendedSNGTariff: ['AB', 'LT', 'LV', 'EE', 'UZ', 'TJ', 'MD', 'KG', 'KZ', 'BY', 'AM', 'AZ'],
  railCountryGlobalTariff: ['MN', 'CN', 'PL', 'BG', 'CZ', 'HU', 'DE', 'FR'],
  railCountryFinlandTariff: ['FI'],
  inlineMode: 0, // Open results, book, checkout etc. in modal
  theme,

  aeroflotSubsidizedTariffCodes: ['PSOCRF', 'PCHSOCRF', 'MSOCRF'],
  maxRequestBookedCount: 24,
  bypassUrlParams: ['channel_token', 'marker'],

  feedbackThemes: {
    returnFlightIssuesThemeId: 316,
    exchange: 291
  },
  enableAEX: false,
  toolNotFoundErrorMessage: 'Tool was not found',
  mapProvider: 'yandex',
  defaultApiErrorMessage: 'Internal error, 00056',
  hotelPaginationLimit: 30,

  // ui-alert configs
  componentTheme: 'partner',
  authEnable: false,

  payment: {
    url: 'payment/create',
    sberPayEnable: false,
    sberPayDeepLinkEnable: false,
    optionsSequence: ['platron-sbp', 'sber-pay', 'tinkoff-pay', 'alfa-podeli', 'altitude']
  }
};

export {
  // eslint-disable-next-line import/prefer-default-export
  baseConfig
};
