import './index.scss';
import cx from 'bem-classnames';

import config from 'actr-app-config';
import PropTypes from 'prop-types';
import React from 'react';

const classes = {
  SiteLoader: { name: 'SiteLoader' },
  Container: { name: 'SiteLoader__Container' },
  Logo: { name: 'SiteLoader__Logo', states: ['theme'] },
  Text: { name: 'SiteLoader__Text', states: ['theme'] },
  Icon: { name: 'SiteLoader__Icon' }
};

// eslint-disable-next-line react/prefer-stateless-function
class SiteLoader extends React.Component {

  static propTypes = {
    beforeContent: PropTypes.any,
    children: PropTypes.any.isRequired,
  };

  static defaultProps = {
    beforeContent: null,
  };

  render() {
    const { beforeContent, children } = this.props;

    const siteLoaderText = config?.theme?.siteLoaderText;

    let logo;
    if (config?.theme?.siteLogo) {
      const fileResolution = config?.theme?.siteLogo === 'vysota' ? 'png' : 'svg';
      logo = (
        <img className={cx(classes.Icon)} src={`${config.baseURL}images/${config?.theme?.siteLogo}.${fileResolution}`} alt="" />
      );
    } else {
      /* eslint-disable max-len */
      logo = (
        <svg height="36" width="36">
          <rect height="38" width="38" fill="none" x="-1" y="-1" />
          <path style={{ fill: '#FFF', stroke: 'null' }} d="m26.5 17.5a0.2 0.2 0 0 1 0-0.3c1.5-1.7 6.6-7.6 8.5-9.7l0.6-0.6c0.3-0.3 0.4-0.6 0.4-0.8 -0.1-0.2-0.5-0.3-0.8-0.3l-3.7 0c-0.8 0-1.4 0.6-1.4 0.6l-8.8 10.2c0 0-0.3 0.4-0.3 0.8 0 0.4 0.3 0.8 0.3 0.8 0 0 8.1 10.6 8.4 11 0.5 0.7 1.3 0.8 1.5 0.8l4 0c0.2 0 0.7-0.1 0.8-0.3 0.1-0.2 0-0.4-0.3-0.8l-9.1-11.4 0 0zm-4.5 10.2a0.9 0.9 0 0 0-0.6-0.8c-0.1 0-0.3-0.1-0.4-0.1 0 0-0.1 0-0.1 0 -0.2 0-1.1-0.2-1.1-1.5 0 0 0 0 0-0.1l0-9.8c0-1.9 0.1-5-2.8-7.8 -1.5-1.5-3.6-2.2-6.3-2.2 -3.2 0-6.3 1.1-7.9 1.7 -0.1 0.1-0.4 0.3-0.3 0.7l0.7 1.6c0.1 0.1 0.3 0.5 0.8 0.5 0.1 0 0.3 0 0.4-0.1 0.5-0.2 1.4-0.3 2.4-0.5 2.6-0.3 4.5 0.1 5.1 0.3 3.3 1 3.3 3.7 3.3 3.7l0 0.2c0 0.1 0.1 0.4-0.6 0.4a47.7 47.7 0 0 0-3.7 0.2c-4.2 0.5-10.8 2.3-10.8 8.8 0 3.1 1.7 7.1 7.2 7.1 3.2 0 5-0.6 5.9-1.1 0.6-0.4 0.2-1 0.2-1s-0.8-1.4-1.1-1.8c0-0.1-0.1-0.1-0.1-0.2 -0.2-0.2-0.6-0.2-0.8-0.2 -0.7 0.1-1.2 0.2-1.9 0.2 -1.2 0-4.1-0.6-4.2-4.1 0-0.5 0-2.8 3-4 0.1 0 0.1-0.1 0.2-0.1l0.2-0.1 0.2-0.1c2.3-0.8 4.6-0.8 5.3-0.8 0 0 0 0 0.1 0 0.9 0 0.9 0.4 0.9 0.5l0 6.6s-0.2 3.4 2.2 5l0 0c0 0 0.1 0 0.1 0 0 0 0.1 0 0.1 0a6.8 6.8 0 0 0 3.3 1.1l0 0c0.3 0 0.7 0 0.9-0.3a0.8 0.8 0 0 0 0.1-0.5l-0.1-1.9 0 0z" />
        </svg>
      );
      /* eslint-enable max-len */
    }

    return (
      <div className={cx(classes.SiteLoader, { theme: config?.theme?.siteLogo })}>
        <div className={cx(classes.Container)}>
          {beforeContent}
          <div className={cx(classes.Logo, { theme: config?.theme?.siteLogo })}>
            {logo}
          </div>
          <div className={cx(classes.Text)}>
            {siteLoaderText || children}
          </div>
        </div>
      </div>
    );
  }

}

export default {};
export {
  SiteLoader
};
