module.exports = {
  // CMS configs
  cmsURL: 'http://s2.cms.ak.deac/v1/',
  cmsAuthToken: '111111',
  cmsMediaUrl: 'http://s3.stage.deac/ms-cms/',
  cmsPages: {
    rules_ru: 3,
    rules_en: 5,
    // insurance_d2_refund_oferta: 71,
    // insurance_d2_refund_rules: 72,
    rail_buy: 75,
    rail_refund: 76,
    // rail_electronic_registration: 77,
    // rail_buy_electronic_ticket: 78,
    // rail_transport_rules: 79,
    // rail_tariff_info: 80,
    // health_declaration: 318, covid_health_declaration
  }
};
