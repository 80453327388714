import { jedTranslator } from 'actr-translator';
import dictRU from 'root/locales/ru_RU/ru_RU.po';

function getTranslator(locale) {
  return jedTranslator(locale === 'ru_RU' ? dictRU : {});
}
export default jedTranslator(dictRU);
export {
  getTranslator
};
