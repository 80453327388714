/* eslint-disable prefer-rest-params */
import isNative from './IsNative';

if (!isNative(Function.prototype.bind)) {
  (function () {
    const ArrayPrototypeSlice = Array.prototype.slice;
    // eslint-disable-next-line no-extend-native
    Function.prototype.bind = function (otherThis) {
      if (typeof this !== 'function') {
        // closest thing possible to the ECMAScript 5
        // internal IsCallable function
        throw new TypeError('Function.prototype.bind - what is trying to be bound is not callable');
      }

      const baseArgs = ArrayPrototypeSlice.call(arguments, 1);
      const baseArgsLength = baseArgs.length;
      const fToBind = this;
      const fNOP = function () {};
      const fBound = function () {
        baseArgs.length = baseArgsLength; // reset to default base arguments
        // eslint-disable-next-line prefer-spread
        baseArgs.push.apply(baseArgs, arguments);
        return fToBind.apply(
          // eslint-disable-next-line no-prototype-builtins
          fNOP.prototype.isPrototypeOf(this) ? this : otherThis, baseArgs
        );
      };

      if (this.prototype) {
        // Function.prototype doesn't have a prototype property
        fNOP.prototype = this.prototype;
      }
      // eslint-disable-next-line new-cap
      fBound.prototype = new fNOP();

      return fBound;
    };
  }());
}
