import { baseConfig } from 'root/config/base';
import cmsDevelopmentConfig from 'root/config/cms_development';

const config = {
  ...baseConfig,
  ...cmsDevelopmentConfig,
  aviakassaUrl: 'http://s2.actr-aviakassa-site.actr-front.deac',
  aviakassaURL: 'http://s2.actr-aviakassa-site.actr-front.deac',
  apiV4URL: '//s2.akl.ak.deac/v4/',
  // apiV4URL: '//localhost:7777/v4/',
  enableApiSwitcher: true,
  enableTracking: true,
};

export default config;
