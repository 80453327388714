import { Intent } from '@actr/ui-legacy/src/mono/common';
import PropTypes from 'prop-types';
import React from 'react';
import { getTranslator } from 'root/translator';
import * as localStorageUtils from 'actr-util-local-storage';

import {
  StyledAlert
} from './style';

class ToolNotFoundAlert extends React.Component {

  static propTypes = {
    id: PropTypes.number.isRequired,
    errorMsg: PropTypes.string.isRequired
  };

  componentDidMount() {
    const { id, errorMsg } = this.props;
    if (window.AkEmitter) {
      window.AkEmitter.emit('partner.tool.not.found', {
        message: errorMsg,
        id
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  get translator() {
    const locale = localStorageUtils.get('locale', ['cookie', 'local']) || 'ru_RU';
    return getTranslator(locale);
  }

  render() {
    return (
      <StyledAlert intent={Intent.INFO}>
        {this.translator.gettext('Loading error')}
      </StyledAlert>
    );
  }

}

export default ToolNotFoundAlert;
