import { lighten, rgba } from 'polished';

const baseFontSize = 14;
const theme = {

  global: {

    // BASE
    baseFontSize,
    appMaxSize: {
      xs: '627px',
      sm: '1024px',
      md: '1280px'
    },
    appMinSize: {
      xs: '300px',
      sm: '628px',
      md: '1025px',
      lg: '1281px'
    },
    animationDuration: {
      base: '.3s'
    },
    spacing: {
      xs: '0.3em',
      sm: '0.5em',
      md: '1em',
      lg: '2em'
    },
    font: 'Open Sans, sans-serif',
    fontSize: {
      xs: `${baseFontSize * 0.8}px`,
      sm: `${baseFontSize * 0.9}px`,
      md: `${baseFontSize}px`,
      lg: '24px'
    },
    lineHeight: {
      base: '1.4em'
    },
    colors: {
      textColor: '#424242',
      secondaryTextColor: rgba(66, 66, 66, 0.5),
      linkColor: '#039BE5',
      accent: '#E53935'
    },
    intentColors: {
      primary: '#039BE5',
      extra: '#5E35B1',
      success: '#7CB342',
      danger: '#E53935',
      info: '#1976D2',
      warning: '#F4511E',
      muted: lighten(0.2, '#424242')
    },

    // INPUT
    input: {
      fontSize: `${baseFontSize}px`,
      bgColor: '#fff',
      borderRadius: '4px',
      borderColor: lighten(0.2, '#424242'),
      paddingHorizontal: '.5em',
      lineHeight: '2.4em',
    },
  },

  searchForm: {
    border: 'none',
    borderRadius: '8px',
    inputHeight: '60px',
    fontSize: '16px',
    boxShadow: '0px 5px 8px 0 #ababab50',
    delimiterColor: '#ababab50',

    submitColor: '#E53935',
    submitTextColor: '#fff',
    submitMaxWidth: '6em',
    submitHeight: '60px',
    submitFontSize: '22px'
  },

  feedback: {
    buttonBgColor: '#039BE5',
    buttonTextColor: '#fff',
    buttonWidth: '240px',
    buttonHeight: '32px'
  }
};

export default theme;
