// eslint-disable-next-line max-classes-per-file
import './styles/style.scss';
import './utils/NativeFunction';
import React from 'react';
import ReactDom from 'react-dom';

import config, { set } from 'actr-app-config';

import Placeholder from 'Containers/placeholder';
import ToolNotFoundAlert from 'Containers/tool-not-found';

class Partner {

  _errorMessage = undefined;

  _editor = null;

  // eslint-disable-next-line class-methods-use-this
  get errorMessage() {
    return this._errorMessage;
  }

  // eslint-disable-next-line class-methods-use-this
  get isProd() {
    return process.env.NODE_ENV === 'production';
  }

  // eslint-disable-next-line class-methods-use-this
  get editor() {
    return this._editor;
  }

  constructor(
    rootId,
    options,
    overrideOptions = {},
    renderCallback
  ) {
    this.options = options;
    set(options);
    this.rootElement = document.getElementById(rootId);
    this.renderEditorWidgets = this.renderEditorWidgets.bind(this);
    this.updateConfig = this.updateConfig.bind(this);
    this.clean = this.clean.bind(this);
    this._loadStylesheet();
    this._draw(renderCallback);
    this._loadFormScript(rootId, options, overrideOptions)
      .catch(err => {
        console.error(err.message);
        throw err;
      });
  }

  async _loadFormScript(rootId, options, overrideOptions) {
    try {
      const FormWidget = (await import('./widgets/form')).default;
      const form = new FormWidget(
        rootId,
        options,
        overrideOptions,
        error => {
          this.form = form;
          this._errorMessage = error;
          this._draw();
        }
      );
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  _loadStylesheet = () => new Promise((resolve, loadFailed) => {
    const isHome = window.location.host === config.baseURL;
    this._resetStylesForContainer();

    if (this.isProd && !isHome) {
      const styleElement = document.createElement('link');
      styleElement.setAttribute('rel', 'stylesheet');
      // eslint-disable-next-line no-undef
      styleElement.setAttribute('href', `${config.baseURL}partner-style-${__webpack_hash__}.css`);
      document.head.appendChild(styleElement);

      styleElement.addEventListener('load', resolve);
      styleElement.addEventListener('error', loadFailed);
    } else {
      resolve();
    }
  }).catch(err => console.error(err));

  _draw(renderCallback = () => {}) {
    ReactDom.unmountComponentAtNode(this.rootElement);

    const formIsReady = Boolean(this.form) && !this._errorMessage;
    // для Placeholder убран пропс errorMessage, так как для фишинговых сайтов отображаем всегда скелет в случае ошибки api
    ReactDom.render(
      // eslint-disable-next-line no-nested-ternary
      formIsReady
        ? this.form.render() : this._errorMessage === config.toolNotFoundErrorMessage
          ? <ToolNotFoundAlert id={this.options.id} errorMsg={this._errorMessage} />
          : <Placeholder options={this.options} />,
      this.rootElement,
      renderCallback
    );
  }

  _drawConfigEditorError(id, message) {
    ReactDom.unmountComponentAtNode(this.rootElement);
    ReactDom.render(
      message === config.toolNotFoundErrorMessage
        ? <ToolNotFoundAlert id={id} errorMsg={message} />
        : <Placeholder options={this.options} />,
      this.rootElement
    );
  }

  _resetStylesForContainer() {
    this.rootElement.style.overflow = 'visible';
  }

  renderEditorWidgets = (rootId, options, callback) => {
    const scriptEditor = document.getElementById('script-editor');
    if (!scriptEditor && this.isProd) {
      return;
    }
    import('./widgets/configEditor')
      .then(module => {
        const ConfigEditor = module.default;
        this._editor = new ConfigEditor(rootId, options, callback);
        // this._editor.addUpdateListener(() => callback(this._editor));
      })
      .catch(err => {
        console.error(err.message);
        throw err;
      });
  }

  updateConfig = options => {
    if (options.errorMsg) {
      return this._drawConfigEditorError(options.id, options.errorMsg);
    }

    if (this.form) {
      this.form.updateConfig(options);
    }

    this.clean();
    this._draw();
    return null;
  }

  clean = () => {
    ReactDom.unmountComponentAtNode(this.rootElement);
  }

}

if (!window.Aviakassa) {
  window.Aviakassa = {};
}

/**
 * @deprecated Use Partner instead of Form.
 * */
window.Aviakassa.Form = Partner;
window.Aviakassa.Partner = Partner;
