import styled, { css } from 'styled-components';

export const Placeholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ paddingRight }) => paddingRight && css`
    padding-right: ${props => props.paddingRight}px;
  `}

  ${({ paddingLeft }) => paddingLeft && css`
    padding-left: ${props => props.paddingLeft}px;
  `}
  
  animation-name: LoadingAnimation;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  
  @keyframes LoadingAnimation {
    0% {
      opacity: .8;
    }
    50% {
      opacity: .5;
    }
    100% {
      opacity: .8;
    }
  }
`;

export const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Text = styled.span`
  font-size: 14px;
  border-radius: 4px;
  height: 20px;
  opacity: .5;
  background-color: #424242;
  color: transparent;
  ${props => props.isLink && css`
    background-color: #039BE5;
  `}
  ${({ height }) => height && css`
    height: ${props => props.height};
  `}
  ${({ width }) => width && css`
    width: ${props => props.width};
  `}
`;

export const Services = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-weight: bold;
  margin-bottom: calc(2em * 1.5);
  
  ${Text} {
    margin: 0 8px;
  }
`;

export const Title = styled.p`
  color: transparent;
  font-size: 24px;
  font-weight: bold;
  max-width: 480px;
  text-align: center;
  margin: 0 0 2em;
`;

export const TitleWrap = styled.span`
  border-radius: 4px;
  line-height: 1.4em;
  opacity: .8;
  background-color: #424242;
`;

export const TopRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 5px 8px 0 #ababab50;
  margin-bottom: 4px;
  
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const SubRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  
  @media screen and (max-width: 627px) {
    flex-direction: column;
  }
`;

export const Input = styled.div`
  width: 100%;
  height: 60px;
  padding: 0 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  
  ${Text} {
    font-size: 16px;
  }
  
  
  @media screen and (max-width: 627px) {
    box-sizing: border-box;
    flex: auto;
    
    ${Text} {
      width: 100% !important;
    }
  }
`;

export const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  
  @media screen and (max-width: 627px) {
    flex-direction: column;
    align-items: center;
    
    ${Text} {
      margin-bottom: 4px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
`;

export const Form = styled.div`
  border-radius: 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 2.8em;
  position: relative;
  
  @media screen and (max-width: 627px) {
    margin-bottom: 0;
  }
`;

export const Button = styled.div`
  height: 60px;
  font-size: 22px;
  width: 6em;
 
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #42424255;
  border-radius: 4px;

  @media screen and (max-width: 627px) {
    width: 100%;
  }
  
  ${Text} {
    background-color: #fff;
    font-size: 22px;
  }
`;

export const LoaderWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 4em 0;
`;
