import React from 'react';
import has from 'lodash/has';
import config from 'actr-app-config';
import { SiteLoader } from 'Components/common/site-loader';
import PropTypes from 'prop-types';

import * as Styled from './style';

const ServicePage = {
  AVIA: '',
  RAIL: 'rail',
  HOTEL: 'hotel'
};

// HELPERS
function validateOptions(options) {

  const requiredFields = [
    // 'showAviaTitle',
    // 'showRailTitle',
    // 'showHotelTitle',
    // 'aviaTitle',
    // 'railTitle',
    // 'hotelTitle',
    'showAvia',
    'showRail',
    'showHotel',
    'showAviakassaLogo',
    'showLocaleSelect',
    'aviaShowComplexRoute'
  ];

  return requiredFields.reduce((prev, field) => (
    prev && has(options, field)
  ), true);
}

function getActiveServiceName(services) {
  if (!window.location) {
    return '';
  }

  if (window.location.hash.length > 0) {
    return window.location.hash.replace('#', '');
  }

  return services[0];
}

function getTitle(activePage, options) {
  if (options.showTitle) {
    return options.aviaTitle || '';
  }
  switch (activePage) {
    case ServicePage.AVIA: return options.showAviaTitle ? options.aviaTitle : null;
    case ServicePage.RAIL: return options.showRailTitle ? options.railTitle : null;
    case ServicePage.HOTEL: return options.showHotelTitle ? options.hotelTitle : null;

    default: return null;
  }
}

function getServices(options) {
  return [
    ...(options.showAvia ? [ServicePage.AVIA] : []),
    ...(options.showRail ? [ServicePage.RAIL] : []),
    ...(options.showHotel ? [ServicePage.HOTEL] : [])
  ];
}

function getServiceName(service) {
  switch (service) {
    case ServicePage.AVIA: return 'Авиа билеты';
    case ServicePage.RAIL: return 'Ж/Д билеты';
    case ServicePage.HOTEL: return 'Отели';

    default: return null;
  }
}

export default function Placeholder({
  errorMessage = null,
  options
}) {

  const services = React.useMemo(() => getServices(options), [options]);
  const activePage = getActiveServiceName(services);
  const title = getTitle(activePage, options);
  const isRoot = Object.values(ServicePage).indexOf(activePage) >= 0;
  const isValidOptions = React.useMemo(() => validateOptions(options), [options]);

  // RENDERERS
  function renderError() {
    return errorMessage;
  }

  function renderService(service, index) {
    return (
      <Styled.Text key={index}>
        {getServiceName(service)}
      </Styled.Text>
    );
  }

  function renderSkeleton() {
    return (
      <Styled.Placeholder paddingRight={options.paddingRight} paddingLeft={options.paddingLeft}>
        <Styled.TextBlock>
          {title && (
            <Styled.Title>
              <Styled.TitleWrap>
                {title}
              </Styled.TitleWrap>
            </Styled.Title>
          )}
        </Styled.TextBlock>
        {config.showNavigationTabs && services.length > 1 && (
          <Styled.Services>
            {services.map(renderService)}
          </Styled.Services>
        )}
        <Styled.TopRow>
          {options.showAviakassaLogo && (<Styled.Text isLink={true} width="100px" height="36px" />)}
          {options.showLocaleSelect && (<Styled.Text isLink={true} width="100px" />)}
        </Styled.TopRow>
        <Styled.Form>
          <Styled.Row>
            <Styled.SubRow>
              <Styled.Input><Styled.Text width="75%">Город отправления</Styled.Text></Styled.Input>
              {activePage !== ServicePage.HOTEL && (
                <Styled.Input><Styled.Text width="75%">Город прибытия</Styled.Text></Styled.Input>
              )}
            </Styled.SubRow>
            <Styled.SubRow>
              <Styled.Input><Styled.Text width="75%">Туда</Styled.Text></Styled.Input>
              <Styled.Input><Styled.Text width="75%">Обратно</Styled.Text></Styled.Input>
              <Styled.Input><Styled.Text width="75%">Эконом</Styled.Text></Styled.Input>
            </Styled.SubRow>
          </Styled.Row>
          {activePage === ServicePage.AVIA && (
            <Styled.BottomRow>
              <div>
                {!!options.aviaShowComplexRoute && (
                  <Styled.Text isLink={true}>Составить сложный маршрут</Styled.Text>
                )}
              </div>
              <div>
                {!!options.showAviaAirlinesPrefilter && (
                  <Styled.Text isLink={true}>Дополнительно</Styled.Text>
                )}
              </div>
            </Styled.BottomRow>
          )}
        </Styled.Form>
        <Styled.Button>
          <Styled.Text inversed={true}>
            Поиск
          </Styled.Text>
        </Styled.Button>
      </Styled.Placeholder>
    );
  }

  function renderLoader() {
    return (
      <Styled.LoaderWrap>
        <SiteLoader beforeContent="">
          Загрузка
        </SiteLoader>
      </Styled.LoaderWrap>
    );
  }

  if (!isValidOptions) {
    return null;
  }

  if (errorMessage) {
    return renderError();
  }

  return isRoot
    ? renderSkeleton()
    : renderLoader();
}

Placeholder.propTypes = {
  errorMessage: PropTypes.string,
  options: PropTypes.object.isRequired,
};

Placeholder.defaultProps = {
  errorMessage: null,
};
